import React from 'react';
import { useSelector } from 'react-redux';
import PropagateLoader from 'react-spinners/PulseLoader';
import { generatallPDF } from '../../utils/InvoicePDF';
import { BsPrinterFill } from 'react-icons/bs';

const CustomerInvoiceProductDetailsColombo = ({ invoiceDetails }) => {
  const { customerInvoiceProductsColombo, isLoading } = useSelector((state) => state.CustomerInvoice);

  const override = {
    display: 'block',
    margin: '0 auto',
    borderColor: 'red',
  };

  return (
    <div className="relative overflow-auto max-h-96">
      {isLoading ? (
        <div className="flex justify-center">
          <PropagateLoader color={'red'} loading={isLoading} cssOverride={override} size={12} aria-label="Loading Spinner" data-testid="loader" />
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row justify-between gap-5 mb-5">
            <div className="w-full md:3/5">
              <label className="block mb-2">Customer Name</label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="customerName"
                value={invoiceDetails.item.customerName}
                disabled={true}
              />
            </div>

            <div className="w-full md:3/5">
              <label className="block mb-2">Location</label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="customerAddress"
                value={invoiceDetails.item.customerAddress}
                disabled={true}
              />
            </div>

            <div className="w-full md:3/5">
              <label className="block mb-2">Phone Number</label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="customerPhone"
                value={invoiceDetails.item.customerPhone}
                disabled={true}
              />
            </div>

            <div className="w-full md:3/5">
              <label className="block mb-2">Invoice No</label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="invoiceNo"
                value={invoiceDetails.item.invoiceNo}
                disabled={true}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between gap-5 mb-5">
            <div className="w-full md:3/5">
              <label className="block mb-2">Customer REF NO/S </label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="customerREFNo"
                value={invoiceDetails.item.customerREFNo}
                disabled={true}
              />
            </div>
            <div className="w-full md:3/5">
              <label className="block mb-2">SK REF NO/S </label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="SKREFNo"
                value={invoiceDetails.item.SKREFNo}
                disabled={true}
              />
            </div>
            <div className="w-full md:3/5">
              <label className="block mb-2">Discount</label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="discount"
                value={`Rs.${invoiceDetails.item.discount}`}
                disabled={true}
              />
            </div>

            <div className="w-full md:w3/5">
              <label className="block mb-2">Total Bill Amount</label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="total"
                value={`Rs.${invoiceDetails.item.total}`}
                disabled={true}
              />
            </div>

            <div className="w-full md:w3/5">
              <label className="block mb-2">Total Bill With Tax</label>
              <input
                className="block p-2 w-full rounded-lg  bg-gray-50 border border-gray-300"
                type="text"
                name="total"
                value={`Rs.${invoiceDetails.item.netTotal}`}
                disabled={true}
              />
            </div>
          </div>

          <div className="flex justify-end">
            <BsPrinterFill
              className="caret-violet-600 cursor-pointer"
              style={{ color: 'black' }}
              size={35}
              title="Export PDF"
              onClick={() =>
                generatallPDF({
                  customerDetails: invoiceDetails.item,
                  products: customerInvoiceProductsColombo,
                  totalPrice: invoiceDetails.item.total,
                })
              }
            />
          </div>

          <table className="table-auto w-full">
            <thead className="bg-black ">
              <tr>
                <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">ID</th>
                <th className="sticky top-0 z-20 text-white px-4 py-3 text-left bg-black ">Product Name</th>
                <th className="sticky top-0 z-20 text-white px-4 py-3 text-left bg-black ">Price</th>
                <th className="sticky top-0 z-20 text-white px-4 py-3 text-left  bg-black ">Quantity</th>
              </tr>
            </thead>
            <tbody>
              {customerInvoiceProductsColombo.map((item) => (
                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-100 even:dark:bg-gray-800" key={item.id}>
                  <td className="text-gray-950 p-5">{item.id}</td>
                  <td className="text-gray-950 p-5">{item.product.productName}</td>
                  <td className="text-gray-950 p-5">Rs.{item.product.sellingPrice}</td>
                  <td className="text-gray-950 p-5 ">{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default CustomerInvoiceProductDetailsColombo;
