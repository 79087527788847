import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/AuthFetch';

const initialState = {
  isLoading: false,
  showAlert: false,
  alertType: '',
  alertText: '',
  searchProducts: [],
  customerInvoices: [],
  customerInvoiceProducts: [],

  searchProductsColombo: [],
  customerInvoicesColombo: [],
  customerInvoiceProductsColombo: [],
};

export const createCustomerInovice = createAsyncThunk('CustomerInvoice/createCustomerInovice', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`product/customerInvoices`, data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note
export const getProductsForSearch = createAsyncThunk('CustomerInvoice/getProductsForSearch', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/directJobNote?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note
export const getCustomerInvoices = createAsyncThunk('CustomerInvoice/getCustomerInvoices', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/customerInvoices?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note
export const getCustomerInvoiceProduct = createAsyncThunk('CustomerInvoice/getCustomerInvoiceProduct', async (invoiceNo, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/customerInvoice/${invoiceNo}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note colombo //////////////////////////////////////
export const getProductsForSearchColombo = createAsyncThunk('CustomerInvoice/getProductsForSearchColombo', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/directJobNoteColombo?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//colombo
export const createCustomerInoviceColombo = createAsyncThunk('CustomerInvoice/createCustomerInoviceColombo', async (data, thunkAPI) => {
  try {
    const resp = await axiosInstance.post(`product/colomboCustomerInvoices`, data);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note colombo
export const getCustomerInvoicesColombo = createAsyncThunk('CustomerInvoice/getCustomerInvoicesColombo', async (searchText, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/colomboCustomerInvoices?searchText=${searchText}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

//get prodcut for search when creating job note colombo
export const getCustomerInvoiceProductColombo = createAsyncThunk('CustomerInvoice/getCustomerInvoiceProductColombo', async (invoiceNo, thunkAPI) => {
  try {
    const resp = await axiosInstance.get(`product/colomboCustomerInvoices/${invoiceNo}`);
    return resp.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

const CustomerInvoiceSlice = createSlice({
  name: 'CustomerInvoice',
  initialState,
  reducers: {
    displayAlert: (state, { payload }) => {
      state.showAlert = true;
      state.alertType = payload ? payload.alertType : 'error';
      state.alertText = payload ? payload.alertText : 'Please provide all values';
    },
    hideAlert: (state) => {
      state.showAlert = false;
      state.alertType = '';
      state.alertText = '';
    },
  },

  extraReducers: (builder) => {
    builder
      //createCustomerInovice
      .addCase(createCustomerInovice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCustomerInovice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully customer invoice is created';
      })
      .addCase(createCustomerInovice.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //createCustomerInoviceColombo
      .addCase(createCustomerInoviceColombo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCustomerInoviceColombo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.alertType = 'success';
        state.alertText = 'successfully customer invoice is created';
      })
      .addCase(createCustomerInoviceColombo.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getProductsForSearch
      .addCase(getProductsForSearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsForSearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchProducts = action.payload;
      })
      .addCase(getProductsForSearch.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getProductsForSearchColombo//////////////////////////////////////////////////////////////
      .addCase(getProductsForSearchColombo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductsForSearchColombo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchProductsColombo = action.payload;
      })
      .addCase(getProductsForSearchColombo.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerInvoices
      .addCase(getCustomerInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInvoices = action.payload;
      })
      .addCase(getCustomerInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerInvoicesColombo
      .addCase(getCustomerInvoicesColombo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerInvoicesColombo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInvoicesColombo = action.payload;
      })
      .addCase(getCustomerInvoicesColombo.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerInvoiceProduct
      .addCase(getCustomerInvoiceProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerInvoiceProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInvoiceProducts = action.payload;
      })
      .addCase(getCustomerInvoiceProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      })

      //getCustomerInvoiceProductColombo
      .addCase(getCustomerInvoiceProductColombo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerInvoiceProductColombo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerInvoiceProductsColombo = action.payload;
      })
      .addCase(getCustomerInvoiceProductColombo.rejected, (state, action) => {
        state.isLoading = false;
        state.alertType = 'error';
        state.alertText = action.payload;
      });
  },
});
export const { displayAlert, hideAlert, isLoading } = CustomerInvoiceSlice.actions;

export default CustomerInvoiceSlice.reducer;
