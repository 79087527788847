import { IoBarChartSharp } from 'react-icons/io5';
import { MdQueryStats } from 'react-icons/md';
import { FaWpforms, FaTruck } from 'react-icons/fa';
import { ImProfile } from 'react-icons/im';
import { FaWarehouse } from 'react-icons/fa';
import { FaShop, FaTruckArrowRight } from 'react-icons/fa6';
import { BsFillPersonLinesFill, BsPersonFillAdd, BsFillPersonVcardFill } from 'react-icons/bs';
import { MdWorkHistory, MdNoteAdd } from 'react-icons/md';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import { MdAddBusiness } from 'react-icons/md';

export const links = [
  { id: 1, text: 'Main Stock', path: '/', icon: <FaWarehouse />, category: 'Stocks', roles: ['admin', 'employee', 'colombo showroom'] },
  {
    id: 2,
    text: 'Vehicle Stock',
    path: 'vehicle-stock',
    icon: <FaTruck />,
    category: 'Stocks',
    roles: ['admin', 'employee', 'colombo showroom'],
  },
  {
    id: 3,
    text: 'Third Party Stock',
    path: 'third-Party-Stock',
    icon: <BsFillPersonLinesFill />,
    category: 'Stocks',
    roles: ['admin', 'employee', 'colombo showroom'],
  },
  { id: 4, text: 'Colombo Showroom', path: 'colombo-showroom', icon: <FaShop />, category: 'Stocks', roles: ['admin', 'colombo showroom'] },
  { id: 5, text: 'Stock History', path: 'stock-history', icon: <MdWorkHistory />, category: 'Stocks', roles: ['admin'] },

  { id: 6, text: 'Add Direct Job Note', path: 'add-direct-jobnote', icon: <MdNoteAdd />, category: 'Job Notes', roles: ['admin', 'employee'] },
  { id: 7, text: 'Add Job Note S.Vehicle', path: 'add-jobnotes', icon: <FaTruckArrowRight />, category: 'Job Notes', roles: ['admin', 'employee'] },
  {
    id: 8,
    text: 'Add JobNote T.Party',
    path: 'add-jobnotes-third-party',
    icon: <BsPersonFillAdd />,
    category: 'Job Notes',
    roles: ['admin', 'employee'],
  },
  { id: 9, text: 'S.Vehicle Job Notes', path: 'job-notes', icon: <FaTruck />, category: 'Job Notes', roles: ['admin', 'employee'] },
  { id: 10, text: 'Direct Job Notes', path: 'direct-jobnotes', icon: <FaWpforms />, category: 'Job Notes', roles: ['admin', 'employee'] },
  {
    id: 11,
    text: 'T.Party Job Notes',
    path: 'thirdparty-jobnotes',
    icon: <BsFillPersonVcardFill />,
    category: 'Job Notes',
    roles: ['admin', 'employee'],
  },

  { id: 12, text: 'Add Customer Invoice', path: 'add-customer-invoice', icon: <LiaFileInvoiceDollarSolid />, category: 'Invoice', roles: ['admin'] },
  { id: 13, text: 'Customer Invoice', path: 'customer-invoices', icon: <FaWpforms />, category: 'Invoice', roles: ['admin'] },
  {
    id: 14,
    text: 'Add Direct Job Note Colombo',
    path: 'add-direct-jobnote-colombo',
    icon: <MdAddBusiness />,
    category: 'Job Notes',
    roles: ['admin', 'colombo showroom'],
  },
  {
    id: 15,
    text: 'Direct Job Notes Colombo',
    path: 'direct-jobnotes-colombo',
    icon: <FaWpforms />,
    category: 'Job Notes',
    roles: ['admin', 'colombo showroom'],
  },
];

export const mobileLinks = [
  { id: 1, text: 'Main Stock', path: '/', icon: <FaWarehouse />, category: 'Stocks', roles: ['admin', 'employee', 'colombo showroom'] },
  {
    id: 2,
    text: 'Vehicle Stock',
    path: 'vehicle-stock',
    icon: <FaTruck />,
    category: 'Stocks',
    roles: ['admin', 'employee', 'colombo showroom'],
  },
  { id: 3, text: 'Third Party Stock', path: 'third-Party-Stock', icon: <BsFillPersonLinesFill />, category: 'Stocks', roles: ['admin', 'employee'] },
  { id: 4, text: 'Colombo Showroom', path: 'colombo-showroom', icon: <FaShop />, category: 'Stocks', roles: ['admin', 'colombo showroom'] },
  { id: 5, text: 'Stock History', path: 'stock-history', icon: <MdWorkHistory />, category: 'Stocks', roles: ['admin'] },
];

export const linksColomboShowroom = [
  { id: 4, text: 'Colombo Showroom', path: 'colombo-showroom', icon: <FaShop />, category: 'Stocks', roles: ['admin', 'colombo showroom'] },

  { id: 1, text: 'Main Stock Kandy', path: '/', icon: <FaWarehouse />, category: 'Stocks', roles: ['admin', 'employee', 'colombo showroom'] },
  {
    id: 2,
    text: 'Vehicle Stock',
    path: 'vehicle-stock',
    icon: <FaTruck />,
    category: 'Stocks',
    roles: ['admin', 'employee', 'colombo showroom'],
  },
  {
    id: 3,
    text: 'Third Party Stock',
    path: 'third-Party-Stock',
    icon: <BsFillPersonLinesFill />,
    category: 'Stocks',
    roles: ['admin', 'employee', 'colombo showroom'],
  },
  {
    id: 4,
    text: 'Add Direct Job Note Colombo',
    path: 'add-direct-jobnote-colombo',
    icon: <MdAddBusiness />,
    category: 'Job Notes',
    roles: ['admin', 'colombo showroom'],
  },
  {
    id: 5,
    text: 'Direct Job Notes Colombo',
    path: 'direct-jobnotes-colombo',
    icon: <FaWpforms />,
    category: 'Job Notes',
    roles: ['admin', 'colombo showroom'],
  },

  {
    id: 6,
    text: 'Add Customer Invoice',
    path: 'add-customer-invoice-colombo',
    icon: <LiaFileInvoiceDollarSolid />,
    category: 'Invoice',
    roles: ['colombo showroom'],
  },

  {
    id: 7,
    text: 'Customer Invoice',
    path: 'customer-invoices-colombo',
    icon: <FaWpforms />,
    category: 'Invoice',
    roles: ['colombo showroom'],
  },
];

export const mobileLinksColomboShowroom = [
  { id: 4, text: 'Colombo Showroom', path: 'colombo-showroom', icon: <ImProfile />, category: 'Stocks', roles: ['admin', 'colombo showroom'] },
  { id: 1, text: 'Main Stock Kandy', path: '/', icon: <IoBarChartSharp />, category: 'Stocks', roles: ['admin', 'employee', 'colombo showroom'] },
  {
    id: 2,
    text: 'Vehicle Stock',
    path: 'vehicle-stock',
    icon: <MdQueryStats />,
    category: 'Stocks',
    roles: ['admin', 'employee', 'colombo showroom'],
  },
  { id: 3, text: 'Third Party Stock', path: 'third-Party-Stock', icon: <MdQueryStats />, category: 'Stocks', roles: ['admin', 'employee'] },
];
